import { gql, useQuery } from '@apollo/client'

import { CrmLawsuitSearchUserQueriesListQuery } from './__generated__/use-get-lawsuit-search-interaction-amount.graphql'

export const LAWSUIT_SEARCH_INTERACTION_QUERIES_LIST = gql`
  query crmLawsuitSearchUserQueriesList($limit: Int, $initialDate: String, $finalDate: String) {
    root {
      crmLawsuitSearchUserQueriesList(
        limit: $limit
        initialDate: $initialDate
        finalDate: $finalDate
      ) {
        relation
      }
    }
  }
`

export function useGetLawsuitSearchInteractionAmount() {
  const { data } = useQuery<CrmLawsuitSearchUserQueriesListQuery>(
    LAWSUIT_SEARCH_INTERACTION_QUERIES_LIST
  )

  const lawsuitSearchAmount = data?.root?.crmLawsuitSearchUserQueriesList?.length

  return { lawsuitSearchAmount }
}
