import courtSearchImage from '../../public/home-info/court-search.svg'
import keepUpdatedImage from '../../public/home-info/keep_updated.svg'
import understandingHappeningImage from '../../public/home-info/understanding_happening.svg'

export const ITEMS_INFO = [
  {
    image: courtSearchImage,
    title: 'Busca otimizada por processos',
    alt: 'Logo busca otimizada por processos',
    description:
      'Encontre processos pelo CPF, nome ou número, utilizando a Consulta Processual do Jusbrasil',
  },
  {
    image: understandingHappeningImage,
    title: 'Entenda o que está acontecendo',
    alt: 'Logo entenda o que está acontecendo',
    description: 'Acesse todas as movimentações, documentos e outras informações do processo',
  },
  {
    image: keepUpdatedImage,
    title: 'Mantenha-se atualizado sobre o processo',
    alt: 'Logo mantenha-se atualizado sobre o processo',
    description: 'Não perca nada, assine para receber notificações a cada novidade do processo',
  },
]
