import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { EventContext } from '@jusbrasil-web/lawsuit/shared'
import { setJusbrasilCookie } from '@jusbrasil-web/shared-utils-cookies'
import React from 'react'

import { convertQueryToBase64, formatText, SearchIntentionOptionEnum } from '../../utils'
import { SearchTypeEnum } from '../lawsuit-search-home/home-search/types'
import {
  CrmLawsuitSearchUserInteractionQuery,
  LawsuitSearchIntentionWrapper_ViewerFragment,
} from './__generated__/lawsuit-search-intention-wrapper.graphql'

export const LAWSUIT_SEARCH_INTERACTION_QUERY = gql`
  query crmLawsuitSearchUserInteraction($queryId: String!) {
    root {
      crmLawsuitSearchUserInteraction(queryId: $queryId) {
        relation
      }
    }
  }
`
export const LAWSUIT_SEARCH_INTERACTION_CREATE_MUTATION = gql`
  mutation createCRMLawsuitSearchUserInteraction(
    $input: createCRMLawsuitSearchUserInteractionInput!
  ) {
    createCRMLawsuitSearchUserInteraction(input: $input) {
      crmLawsuitSearchUserInteraction {
        query
        queryId
        relation
      }
    }
  }
`
export const LAWSUIT_SEARCH_INTERACTION_INCREMENT_MUTATION = gql`
  mutation incrementCRMLawsuitSearchUserInteraction(
    $input: incrementCRMLawsuitSearchUserInteractionInput!
  ) {
    incrementCRMLawsuitSearchUserInteraction(input: $input) {
      crmLawsuitSearchUserInteraction {
        count
      }
    }
  }
`

export interface HandleSearchCallbackResult {
  isFormValid?: boolean
  searchType?: SearchTypeEnum
  searchValue?: string
  successCallback?: () => void
}

export type InterceptSearchType = (handleSearch: () => HandleSearchCallbackResult) => void

interface SearchIntentionData {
  query: string
  queryId: string
}

interface LawsuitSearchIntentionWrapperProps {
  children: (props: { interceptSearch: InterceptSearchType }) => React.ReactNode
  viewer: LawsuitSearchIntentionWrapper_ViewerFragment | null
  eventContext: EventContext
}

function getQueryData(searchType: SearchTypeEnum, searchValue: string): SearchIntentionData {
  const [regex, replace] =
    searchType === SearchTypeEnum.Search ? [/(\d)([._]+)/g, '$1'] : [/\D/g, '']

  const clearedQuery = searchValue.replace(regex, replace)

  return {
    query: formatText(clearedQuery),
    queryId: convertQueryToBase64(clearedQuery),
  }
}

function matchName(query: string, name?: string | null): boolean {
  return formatText(query) === formatText(name || '')
}

export function LawsuitSearchIntentionWrapper(props: LawsuitSearchIntentionWrapperProps) {
  const { children, viewer } = props
  const isLogged = !!viewer?.pid

  const [lawsuitSearchInteraction] = useLazyQuery<CrmLawsuitSearchUserInteractionQuery>(
    LAWSUIT_SEARCH_INTERACTION_QUERY
  )

  const [searchInteractionCreateMutation] = useMutation(LAWSUIT_SEARCH_INTERACTION_CREATE_MUTATION)

  const [searchInteractionIncrementMutation] = useMutation(
    LAWSUIT_SEARCH_INTERACTION_INCREMENT_MUTATION
  )

  const getIntentionHistory = async (queryId: string) => {
    const { data } = await lawsuitSearchInteraction({ variables: { queryId } }).catch((error) => {
      console.error(error)
      return { data: null }
    })
    return data?.root?.crmLawsuitSearchUserInteraction
  }

  const createSearchIntention = async (
    relation: SearchIntentionOptionEnum,
    searchIntentionData: SearchIntentionData
  ) => {
    const { query, queryId } = searchIntentionData

    await searchInteractionCreateMutation({
      variables: { input: { query, queryId, relation } },
    }).catch(console.error)
  }

  const handleSaveIntention = async (
    searchData: SearchIntentionData,
    handleSearchResult: HandleSearchCallbackResult
  ) => {
    const { query, queryId } = searchData
    const { successCallback, searchType } = handleSearchResult

    if (!isLogged) {
      const in15Minutes = new Date(new Date().setMinutes(new Date().getMinutes() + 15))
      setJusbrasilCookie(
        null,
        'lawsuit_search_intention',
        Buffer.from(
          JSON.stringify({ ...searchData, relation: SearchIntentionOptionEnum.UNKNOWN })
        ).toString('base64'),
        {
          expires: in15Minutes,
        }
      )
      return successCallback?.()
    }

    const intention = await getIntentionHistory(queryId)
    const hasHistory = !!intention

    if (hasHistory) {
      return searchInteractionIncrementMutation({ variables: { input: { queryId } } })
        .catch(console.error)
        .finally(() => successCallback?.())
    }

    const isPartyIntention =
      (searchType === SearchTypeEnum.CPF && query === viewer?.cpf) ||
      (searchType === SearchTypeEnum.Search && matchName(query, viewer?.cpfName))

    const inferredIntention = isPartyIntention
      ? SearchIntentionOptionEnum.PARTY
      : SearchIntentionOptionEnum.UNKNOWN

    return createSearchIntention(inferredIntention, searchData)
      .catch(console.error)
      .finally(() => successCallback?.())
  }

  const interceptSearch: InterceptSearchType = (handleSearch) => {
    const handleSearchResult = handleSearch()
    const { isFormValid, successCallback, searchType, searchValue } = handleSearchResult

    if (!isFormValid) return

    if (searchType && searchValue && successCallback) {
      const queryData = getQueryData(searchType, searchValue)

      handleSaveIntention(queryData, handleSearchResult).catch(console.error)
    }
  }

  return <React.Fragment>{children({ interceptSearch })}</React.Fragment>
}

LawsuitSearchIntentionWrapper.fragments = {
  viewer: gql`
    fragment LawsuitSearchIntentionWrapper_viewer on Profile {
      pid
      cpf
      cpfName
    }
  `,
}
