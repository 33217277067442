export const FAQ_QUESTIONS = [
  {
    id: '1',
    title: 'Como consultar um processo pelo Jusbrasil?',
    description:
      'Você pode consultar processos por CPF, por nome ou por número através da nossa página de consulta processual. Os resultados da nossa busca refletem as informações dos sistemas dos tribunais.',
  },
  {
    id: '2',
    title: 'Quais tipos de processo posso consultar e acompanhar pelo Jusbrasil?',
    description: `No Jusbrasil você pode ter acesso aos processos trabalhistas, do consumidor,
    criminais ou de família, e em qualquer instância processual (1ª ou 2ª instância).`,
  },
  {
    id: '3',
    title: 'Em quais tribunais posso consultar processos?',
    description: ` Consulte e acompanhe processos de tribunais federais, tribunais de justiça,
    tribunais do trabalho, tribunais de contas e tribunais militares. Confira abaixo a lista
    completa dos tribunais disponíveis.`,
  },
  {
    id: '4',
    title: 'Como posso melhorar os resultados da minha consulta processual?',
    description:
      'Pesquise pelo CPF de alguma das pessoas envolvidas. Se não encontrar resultados, pesquise um nome por vez, e preferencialmente um nome completo, ou um número de processo no padrão CNJ.',
  },
  {
    id: '5',
    title: 'Quando pesquiso um processo, as partes envolvidas ficam sabendo?',
    description: `As partes de um processo não são comunicadas quando alguém acessa informações
    sobre um processo no Jusbrasil.`,
  },
  {
    id: '6',
    title: 'Como verificar a situação o meu processo no Jusbrasil?',
    description: `Verifique a situação para entender o que está acontecendo no processo clicando em “Verificar situação"
    e nós te avisaremos quando acontecerem novas movimentações nos sistemas dos tribunais ou nos Diários Oficiais.`,
  },
  {
    id: '7',
    title: 'Como acompanhar um nome pelo Jusbrasil?',
    description: `Ao encontrar a página de um nome que deseja acompanhar, clique em "Acompanhar
    Nome". O Jusbrasil verifica diariamente os Diários Oficiais e te notificará quando uma nova
    publicação aparecer.`,
  },
]
