import { SEOBreadcrumb } from '../../types'

export interface SEOGetBreadcrumbInput {
  canonicalUrl: string
  name: string
  artifactProps?: Record<string, string>
}

export const getBreadcrumb = ({
  name,
  canonicalUrl,
  artifactProps,
}: SEOGetBreadcrumbInput): SEOBreadcrumb => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Home',
      item: 'https://www.jusbrasil.com.br',
    },
    ...(artifactProps
      ? [
          {
            '@type': 'ListItem',
            position: 2,
            name: artifactProps.label,
            item: `https://www.jusbrasil.com.br/${artifactProps.pathSegment}/`,
          },
        ]
      : []),
    {
      '@type': 'ListItem',
      position: artifactProps ? 3 : 2,
      name,
      item: canonicalUrl,
    },
  ],
})
