import { Accordion, List } from '@farol-ds/react'

import styles from './faq-accordion.module.scss'
import { FAQ_QUESTIONS } from './questions'

export function FaqAccordion() {
  return (
    <List className={styles.faq}>
      {FAQ_QUESTIONS.map(({ id, title, description }) => (
        <Accordion collapsible type="single" key={id} asChild>
          <Accordion.Item value={id} asChild>
            <List.Item key={id} value={id}>
              <Accordion.Header>
                <Accordion.Title asChild>
                  <h3>{title}</h3>
                </Accordion.Title>
              </Accordion.Header>
              <Accordion.Content>
                <Accordion.Text>
                  <p>{description}</p>
                </Accordion.Text>
              </Accordion.Content>
            </List.Item>
          </Accordion.Item>
        </Accordion>
      ))}
    </List>
  )
}
