import { BASE_URL } from '@jusbrasil-web/lawsuit/shared'
import Head from 'next/head'

import { getStructuredData } from '../../utils/seo/structured-data'
import { CourtTopicType } from '../lawsuit-search-home/types'

const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || ''
const FACEBOOK_ADMINS = process.env.NEXT_PUBLIC_FACEBOOK_ADMINS || ''

type SearchSEOTagsProps = {
  courtTopic?: CourtTopicType | null
}

const CANONICAL_URL = 'https://www.jusbrasil.com.br/consulta-processual/'

const facebookMeta = (title: string, description: string, url: string) => [
  { property: 'fb:app_id', content: FACEBOOK_APP_ID },
  { property: 'fb:admins', content: FACEBOOK_ADMINS },
  {
    property: 'og:image',
    content: 'https://static.jusbr.com/images/logo/logo-jusbrasil-1200-675.png',
  },
  { property: 'og:image:alt', content: 'Imagem Social Consulta Processual' },
  { property: 'og:image:type', content: 'image/png' },
  { property: 'og:image:width', content: '1200' },
  { property: 'og:image:height', content: '675' },
  { property: 'og:title', content: title },
  { property: 'og:description', content: description },
  { property: 'og:url', content: url },
  { property: 'og:type', content: 'website' },
  { property: 'og:locale', content: 'pt_BR' },
  { property: 'og:site_name', content: 'Jusbrasil' },
]

const KEYWORDS = [
  'consulta processual',
  'consulta processo',
  'processo judicial',
  'andamento processual',
  'acompanhar processo',
  'acompanhamento processual',
  'consultar processo',
  'monitorar processo',
  'busca processo',
  'encontrar processo',
  'publicações de processo',
  'consulta unificada',
]

export function getDataFromCourt(acronym: string, alias: string) {
  const formatCourtAcronym = (courtAcronym = '') => courtAcronym.replace(/-/g, '')
  return {
    title: `Consulta Processual no ${acronym} (${alias}) | Jusbrasil`,
    url: `${BASE_URL}/consulta-processual/${formatCourtAcronym(acronym).toLowerCase()}/`,
    description: `Consulta Processual no ${acronym} de maneira rápida, objetiva e gratuita. Acesse o Jusbrasil para buscar processos nos Tribunais.`,
    keywords: KEYWORDS.map((keyword) => `${keyword} ${acronym}`),
  }
}

function getDataForHome() {
  return {
    title: 'Consulta Processual | Consulte seus Processos no Jusbrasil',
    url: `${BASE_URL}/consulta-processual/`,
    description:
      'Acesse o Jusbrasil para Consultar Processos por CPF, Nome ou Número nos Tribunais e Diários Oficiais de todo Brasil. Seja notificado a cada atualização!',
    keywords: KEYWORDS,
  }
}

export const PageHead = ({ courtTopic }: SearchSEOTagsProps) => {
  const { acronym, alias } = courtTopic || {}
  const isCourtPage = acronym && alias

  const pageMetadata = isCourtPage ? getDataFromCourt(acronym, alias) : getDataForHome()
  const { title, description, url, keywords } = pageMetadata

  const metaTags = [
    { name: 'description', content: description },
    { name: 'keywords', content: keywords.join(', ') },
    ...facebookMeta(title, description, url),
  ]

  return (
    <Head>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      {metaTags.map((props, idx) => (
        <meta {...props} key={idx} />
      ))}
      <link rel="canonical" href={CANONICAL_URL} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(getStructuredData()) }}
      />
    </Head>
  )
}
