import { BodyText, Col, Container, Heading, List, Row } from '@farol-ds/react'
import { Image } from '@jusbrasil-web/shared-next-image'

import { ITEMS_INFO } from './constants'
import styles from './search-onboarding.module.scss'

export function SearchOnboarding() {
  return (
    <section>
      <Container>
        <Row>
          <Col xs={12} className={styles.title}>
            <Heading size="xl" asChild>
              <h2>Como consultar um processo no Jusbrasil?</h2>
            </Heading>
          </Col>
        </Row>

        <Row>
          <Col xs={12} asChild>
            <List className={styles.list}>
              {ITEMS_INFO.map(({ image, title, description, alt }, index) => (
                <Col xs={12} sm={4} key={index} className={styles.item} asChild>
                  <List.Item data-testid="list-item">
                    <Image
                      className={styles.image}
                      src={image}
                      alt={alt}
                      priority
                      height={148}
                      width={148}
                      title={title}
                    />
                    <Heading className={styles.imagetitle} asChild>
                      <h3>{title}</h3>
                    </Heading>
                    <BodyText className={styles.description}>{description}</BodyText>
                  </List.Item>
                </Col>
              ))}
            </List>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
