import { gql } from '@apollo/client'
import { BodyText, Col, Container, Heading, Row } from '@farol-ds/react'
import { useExperiments } from '@jusbrasil-web/lawsuit/shared'
import { useGA4Dispatcher } from '@jusbrasil-web/shared-ga4'
import {
  COMPONENT_CLASS,
  COMPONENT_NAME,
  GA4ClickEvent,
  SELECTED_OPTION,
} from '@jusbrasil-web/shared-ga4-events'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import classNames from 'classnames'

import { EXPERIMENTS, VARIANTS } from '../../utils'
import { LawsuitCarousel } from '../lawsuit-carousel'
import {
  LawsuitHistoryQuery,
  LawsuitHistoryViews_RootFragment,
  LawsuitHistoryViews_ViewerFragment,
} from './__generated__/lawsuit-history-views.graphql'
import styles from './lawsuit-history-views.module.scss'

export interface LawsuitHistoryViewsProps {
  viewer: LawsuitHistoryViews_ViewerFragment | null
  root: LawsuitHistoryViews_RootFragment | null
  localLawsuitHistoryItems?: LawsuitHistoryQuery[]
}
type LawsuitHistoryItem = NonNullable<LawsuitHistoryViews_RootFragment['lawsuitHistory']>[number]

export const LAWSUIT_HISTORY_QUERY = gql`
  query lawsuitHistory($lawsuitId: String!) {
    root {
      crmLawsuit(lawsuitId: $lawsuitId) {
        number
        ...LawsuitCarousel_lawsuit
      }
    }
  }
  ${LawsuitCarousel.fragments.lawsuit}
`
export function LawsuitHistoryViews(props: LawsuitHistoryViewsProps) {
  const { root, viewer } = props
  const lawsuitHistory = root?.lawsuitHistory || []
  const networkLawsuitHistory = lawsuitHistory.filter((item: LawsuitHistoryItem) => item)

  const { isParticipating } = useExperiments()
  const isParticipatingTopbarExperiment =
    isParticipating(EXPERIMENTS.LAWSUIT_SEARCH_TOPBAR, VARIANTS.VARIANT_B) ||
    isParticipating(EXPERIMENTS.LAWSUIT_SEARCH_TOPBAR, VARIANTS.VARIANT_C)

  const { sendGA4Event } = useGA4Dispatcher()

  const trackLastSeenClickEvent = (lawsuitItem: LawsuitHistoryItem, position: number) => {
    const pid = viewer?.pid?.toString() || null

    const eventData = {
      metadata: {
        type: 'last_seen_lawsuit',
        isSubscriber: viewer?.hasPaidPlan || false,
        lawsuitPosition: position,
        lawsuitTitle: lawsuitItem.title,
        lawsuitTopicId: lawsuitItem.topicSourceId,
      },
    }

    sendMetrics('SearchLawsuit.LawsuitCardClicked', pid, eventData)

    sendGA4Event(
      new GA4ClickEvent({
        componentClass: COMPONENT_CLASS.LAWSUIT_CARD,
        componentName: COMPONENT_NAME.LAST_LAWSUIT_VIEWED,
        selectedOption: SELECTED_OPTION.SHOW_LAWSUIT,
        resourceId: lawsuitItem.lawsuitId,
      })
    )
  }

  return (
    <section className={styles.section}>
      <Container>
        <Row>
          <Col xs={12}>
            <Heading
              size="lg"
              className={classNames(styles.title, {
                [styles.titleWithoutDescription]: isParticipatingTopbarExperiment,
              })}
              asChild
            >
              <h3>Processos que você acessou</h3>
            </Heading>
            {!isParticipatingTopbarExperiment && (
              <BodyText className={styles.description}>
                Últimos processos acessados por você.
              </BodyText>
            )}
          </Col>
        </Row>
      </Container>
      <LawsuitCarousel lawsuit={networkLawsuitHistory} onClickCard={trackLastSeenClickEvent} />
    </section>
  )
}

LawsuitHistoryViews.fragments = {
  viewer: gql`
    fragment LawsuitHistoryViews_viewer on Profile {
      pid
      hasPaidPlan
    }
  `,

  root: gql`
    fragment LawsuitHistoryViews_root on RootApi {
      lawsuitHistory: crmLawsuitsHistory {
        number
        ...LawsuitCarousel_lawsuit
      }
    }
    ${LawsuitCarousel.fragments.lawsuit}
  `,
}
