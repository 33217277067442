import {
  getBreadcrumb,
  getFAQ,
  getHowTo,
  getStructuredDataSchema,
  getWebpage,
} from '@jusbrasil-web/shared-utils-seo'

import { FAQ_QUESTIONS } from '../../components/lawsuit-search-faq/faq-accordion/questions'

const name = 'Consulta processual'
const url = 'https://www.jusbrasil.com.br/consulta-processual/'

const howToParams = {
  name: 'Como consultar um processo no Jusbrasil?',
  stepItems: [
    {
      text: 'Consulte por um nome ou número de um processo através da consulta processual do Jusbrasil',
      name: 'Busque processos ou nomes',
    },
    {
      text: 'Acesse todas movimentações, documentos e outras informações do processo',
      name: 'Entenda o que está acontecendo',
    },
    {
      text: 'Busque por atualizações do processo e receba notificações sempre que houverem novidades',
      name: 'Se atualize sobre o processo',
    },
  ],
}

export const getStructuredData = () => {
  return getStructuredDataSchema({
    webpage: getWebpage({
      description:
        'Consulta Processual nos Tribunais e Diários Oficiais gratuitamente. Acompanhe e seja notificado a cada atualização do seu processo com o Jusbrasil PRO. Acesse para pesquisar ou buscar Processos nos Tribunais Superiores, Cortes de Uniformização, Tribunais Regionais Federais (TRF), Tribunais Regionais Eleitorais (TRE), Tribunais Regionais do Trabalho (TRT), Tribunais de Justiça (TJ), Tribunais de Contas dos Estados (TCE), Tribunal de Justiça Militar (TJM). Visite o Jusbrasil.',
      canonicalUrl: url,
      name,
      image: { name },
    }),
    breadcrumb: getBreadcrumb({
      name,
      canonicalUrl: url,
    }),
    faq: getFAQ(
      FAQ_QUESTIONS.map(({ title, description }) => ({
        question: title,
        answer: description,
      }))
    ),
    howTo: getHowTo({
      name: howToParams.name,
      stepItems: howToParams.stepItems,
    }),
  })
}
