import { gql } from '@apollo/client'
import { BASE_URL } from '@jusbrasil-web/lawsuit/shared'
import { initializeApollo } from '@jusbrasil-web/shared-apollo-client'
import { getFirstQueryParam } from '@jusbrasil-web/shared-utils-url'
import { NextApiRequest, NextApiResponse } from 'next'
import { configure, get_current_user as getCurrentUser } from 'tornado-auth-js'

import { getEventData, sendMetricsFromServer } from '../../../utils'
import { clearCPFString, isCPFNumber } from '../../../utils/cpf'
import { PartyEntityUrlQuery, PartyEntityUrlQueryVariables } from './__generated__/handler.graphql'

configure({
  secret_key: process.env.SECRET_KEY,
  max_age_days: 365,
})

type GetUserProps = {
  pid: number
}

const PARTY_ENTITY_URL_QUERY = gql`
  query PartyEntityUrl(
    $identificationType: String!
    $identificationNumber: String!
    $userToken: String
  ) {
    root(auth: $userToken) {
      partyEntityUrl(
        identificationType: $identificationType
        identificationNumber: $identificationNumber
      )
    }
  }
`

export const THIRD_PARTY_BACKGROUND_CHECK_MUTATION = gql`
  mutation thirdPartySearchFeatureMutation($searchCPF: String!) {
    useThirdPartySearchFeature(input: { searchCpf: $searchCPF }) {
      success
      upperBoundLimitReached
    }
  }
`

export const cpfApiHandler = async (req: NextApiRequest, res: NextApiResponse): Promise<void> => {
  const cpfNumber = getFirstQueryParam(req.query.cpfNumber)
  const userToken = getFirstQueryParam(req.cookies.user)
  const fromComponent = getFirstQueryParam(req.query.from_component) || null
  const getUser = req.cookies.user && (getCurrentUser(req.cookies.user) as GetUserProps)
  const user = getUser ? getUser.pid : null
  const pid = user?.toString() as string
  const isAdmin = getFirstQueryParam(req.cookies.admin) || null
  const isFromSerp = req.query.from_serp
  const queryId = req.query.query_id || null
  const queryIdUrl = queryId ? `query_id=${queryId}` : ''
  const intention = getFirstQueryParam(req.query.intention) || null
  const intentionUrl = intention ? `&intention=${intention}` : ''
  const cpfNotFoundUrl = `${BASE_URL}/consulta-processual/?${queryIdUrl}&q=${cpfNumber}&from_component=${fromComponent}${intentionUrl}&redirect=cpf_not_found`
  const clearedCPFString = clearCPFString(cpfNumber)

  const cookies = req.headers.cookie
  const cookiesHeader = !cookies ? {} : { Cookie: cookies }
  const headers = { ...cookiesHeader }

  try {
    const client = initializeApollo(undefined, req)

    const { data: thirdPartyBackgroundCheckData } = await client.mutate({
      mutation: THIRD_PARTY_BACKGROUND_CHECK_MUTATION,
      variables: { searchCPF: clearedCPFString },
    })
    if (thirdPartyBackgroundCheckData?.useThirdPartySearchFeature?.upperBoundLimitReached) {
      res.redirect('/error/limite-excedido-consulta-cpf.html')
      return
    }

    const partyEntityVariables: PartyEntityUrlQueryVariables = {
      identificationType: 'CPF',
      identificationNumber: cpfNumber,
      userToken,
    }
    const { data } = await client.query<PartyEntityUrlQuery>({
      query: PARTY_ENTITY_URL_QUERY,
      variables: partyEntityVariables,
    })

    const partyEntityUrl = data.root.partyEntityUrl
    const hasNoResult = !isCPFNumber(cpfNumber) || partyEntityUrl === ''
    const entityId = partyEntityUrl ? partyEntityUrl.match(/cpf-(.*)/)?.[1] : ''
    const eventDocument = entityId ? `LAWSUIT_PARTY_ENTITY-${entityId}` : null

    const cpfEventData = getEventData(
      req,
      cpfNumber,
      clearedCPFString,
      fromComponent,
      eventDocument,
      !hasNoResult,
      pid,
      isAdmin
    )

    if (isFromSerp) {
      sendMetricsFromServer('SearchLawsuit.Submitted', pid, cpfEventData, headers)
    }

    if (hasNoResult) {
      res.redirect(cpfNotFoundUrl)
      return
    }

    sendMetricsFromServer('Search.Viewed', pid, cpfEventData, headers)
    res.redirect(`${partyEntityUrl}?${queryIdUrl}${intentionUrl}`)
  } catch (e) {
    res.redirect(cpfNotFoundUrl)
  }
}
