import { SEOFAQPage } from '../../types'

export interface SEOFAQInput {
  question: string
  answer: string
}

export const getFAQ = (faqItems: SEOFAQInput[]): SEOFAQPage => ({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: faqItems.map(({ question, answer }) => ({
    '@type': 'Question',
    name: question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: answer,
    },
  })),
})
