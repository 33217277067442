import { ABTestParticipation } from '@jusbrasil-web/shared-ab-test'

export const EXPERIMENTS = {
  LAWSUIT_SEARCH_TOPBAR: 'lawsuit-search-topbar',
}

// Use the same group for all experiments
export const GROUPS = {
  LAWSUIT: 'lawsuit',
  LAWSUIT_SEARCH: 'lawsuit-search',
  NOTIFICATION: 'notification',
}

export const VARIANTS = {
  CONTROL: 'control',
  VARIANT_B: 'variantB',
  VARIANT_C: 'variantC',
}

export const EXPERIMENTS_CONFIG: ABTestParticipation = {
  experiments: Object.values(EXPERIMENTS),
  groups: Object.values(GROUPS),
  participatingOnly: true,
}
