import { gql } from '@apollo/client'
import { BASE_URL } from '@jusbrasil-web/lawsuit/shared'
import { initializeApollo } from '@jusbrasil-web/shared-apollo-client'
import { LawsuitNumber } from '@jusbrasil-web/shared-utils-cnj'
import { getFirstQueryParam } from '@jusbrasil-web/shared-utils-url'
import { NextApiRequest, NextApiResponse } from 'next'
import { configure, get_current_user as getCurrentUser } from 'tornado-auth-js'

import { getEventData, sendMetricsFromServer } from '../../../utils'
import {
  LawsuitSearchFormSearchLawsuitMutation,
  LawsuitSearchFormSearchLawsuitMutationVariables,
} from './__generated__/handler.graphql'

configure({
  secret_key: process.env.SECRET_KEY,
  max_age_days: 365,
})

type GetUserProps = {
  pid: number
}

const SEARCH_LAWSUIT_MUTATION = gql`
  mutation LawsuitSearchFormSearchLawsuit($input: SearchLawsuitInput!) {
    searchLawsuit(input: $input) {
      lawsuitId
      lawsuitUrl
    }
  }
`

export const lawsuitNumberApiHandler = async (
  req: NextApiRequest,
  res: NextApiResponse
): Promise<void> => {
  const rawLawsuitNumber = getFirstQueryParam(req.query.lawsuitNumber)
  const utmSourceQueryString = getFirstQueryParam(req.query.utm_source)
  const fromComponent = getFirstQueryParam(req.query.from_component) || null
  const isAdmin = getFirstQueryParam(req.cookies.admin) || null
  const getUser = req.cookies.user && (getCurrentUser(req.cookies.user) as GetUserProps)
  const user = getUser ? getUser.pid : null
  const pid = user?.toString() as string
  const lawsuitNumber = new LawsuitNumber(rawLawsuitNumber)
  const cnjNumber = lawsuitNumber.isValid ? lawsuitNumber.toString() : rawLawsuitNumber

  const cookies = req.headers.cookie
  const cookiesHeader = !cookies ? {} : { Cookie: cookies }
  const headers = { ...cookiesHeader }

  try {
    const client = initializeApollo(undefined, req)
    const variables: LawsuitSearchFormSearchLawsuitMutationVariables = {
      input: { cnjNumber },
    }
    const { data } = await client.mutate<LawsuitSearchFormSearchLawsuitMutation>({
      mutation: SEARCH_LAWSUIT_MUTATION,
      variables,
    })

    const lawsuitId = data?.searchLawsuit?.lawsuitId
    const lawsuitUrl = data?.searchLawsuit?.lawsuitUrl

    const utmSource = utmSourceQueryString ? `?utm_source=${utmSourceQueryString}` : ''
    const lawsuitSearchUrl = `${BASE_URL}/processos/consulta/${lawsuitId}`

    const lawsuitGoToUrl = `/consulta-processual/goto/${cnjNumber}`
    const redirectUrl =
      lawsuitUrl && !lawsuitUrl.includes(lawsuitGoToUrl) ? lawsuitUrl : lawsuitSearchUrl

    const eventDocument = lawsuitId ? `LAWSUIT-${lawsuitId}` : null

    const cnjEventData = getEventData(
      req,
      rawLawsuitNumber,
      cnjNumber,
      fromComponent,
      eventDocument,
      true,
      pid,
      isAdmin
    )

    if (lawsuitUrl && !lawsuitUrl.includes(lawsuitGoToUrl)) {
      sendMetricsFromServer('Search.Viewed', pid?.toString(), cnjEventData, headers)
    }

    res.redirect(`${redirectUrl}${utmSource}`)
  } catch (e) {
    const cnjEventData = getEventData(
      req,
      rawLawsuitNumber,
      cnjNumber,
      fromComponent,
      null,
      true,
      pid,
      isAdmin
    )
    sendMetricsFromServer('Search.Viewed', pid, cnjEventData, headers)
    res.redirect(`${BASE_URL}/busca?q=${rawLawsuitNumber}`)
  }
}
