import { gql } from '@apollo/client'
import { Carousel, Col, Container, Row } from '@farol-ds/react'
import { LawsuitCard } from '@jusbrasil-web/lawsuit/shared'
import { useGA4Dispatcher } from '@jusbrasil-web/shared-ga4'
import { GA4ViewItemListEvent } from '@jusbrasil-web/shared-ga4-events'
import { useEffect } from 'react'

import { LawsuitCarousel_LawsuitFragment } from './__generated__/lawsuit-carousel.graphql'
import styles from './lawsuit-carousel.module.scss'

export interface LawsuitCarouselProps {
  lawsuit: LawsuitCarousel_LawsuitFragment[]
  onClickCard: (lawsuitItem: LawsuitCarousel_LawsuitFragment, position: number) => void
}

export function LawsuitCarousel(props: LawsuitCarouselProps) {
  const { lawsuit, onClickCard } = props
  const { sendGA4Event } = useGA4Dispatcher()
  const blockedDisplayClass = lawsuit.length === 2 ? styles.blockedDisplay : ''

  useEffect(() => {
    sendGA4Event(
      new GA4ViewItemListEvent({
        componentClass: null,
        componentName: null,
        renders: lawsuit?.map((lawsuit) => ({
          item_variant: 'show_lawsuit',
          item_brand: 'lawsuit_card',
          item_name: 'last_lawsuit_viewed',
          item_id: lawsuit.lawsuitId,
        })),
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Carousel>
      <Container unstable_spacing={{ xs: 'full' }}>
        <Carousel.List className={styles.list}>
          {lawsuit.map((lawsuitItem, index) => (
            <Carousel.Item className={styles.item} key={lawsuitItem?.number}>
              <LawsuitCard
                lawsuit={lawsuitItem?.publicLawsuit || lawsuitItem}
                className={styles.cardCarousel}
                showLawsuitNumber
                showParts={!!lawsuitItem.canViewRestrictLawsuit}
                truncateText
                onClick={() => onClickCard(lawsuitItem, index + 1)}
              />
            </Carousel.Item>
          ))}
        </Carousel.List>
      </Container>
      <Container>
        <Row>
          <Col xs={12}>
            <div className={styles.pagination}>
              {lawsuit.length > 1 && <Carousel.Pagination className={blockedDisplayClass} />}
            </div>
          </Col>
        </Row>
      </Container>
    </Carousel>
  )
}

LawsuitCarousel.fragments = {
  lawsuit: gql`
    fragment LawsuitCarousel_lawsuit on CRMLawsuit {
      id
      lawsuitId
      ...LawsuitCard_lawsuit
      publicLawsuit {
        id
        ...LawsuitCard_lawsuit
      }
    }
    ${LawsuitCard.fragments.lawsuit}
  `,
}
