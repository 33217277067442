import { gql } from '@apollo/client'
import { Container } from '@farol-ds/react'
import { EventContext } from '@jusbrasil-web/lawsuit/shared'

import { SearchPageError } from '../../utils'
import { Home_ViewerFragment } from './__generated__/home.graphql'
import { HomeSearch } from './home-search'
import { HomeTitle } from './home-title'
import { CourtTopicType } from './types'

export interface HomeProps {
  eventContext: EventContext
  courtTopic: CourtTopicType | null
  viewer: Home_ViewerFragment | null
  pageError?: SearchPageError
  currentUrl?: string
}

export function Home(props: HomeProps) {
  const { viewer, eventContext, courtTopic, pageError, currentUrl } = props
  return (
    <section>
      <Container>
        <HomeTitle courtTopic={courtTopic} pageError={pageError} />
        <HomeSearch viewer={viewer} eventContext={eventContext} currentUrl={currentUrl} />
      </Container>
    </section>
  )
}

Home.fragments = {
  viewer: gql`
    fragment Home_viewer on Profile {
      ...HomeSearch_viewer
    }
    ${HomeSearch.fragments.viewer}
  `,
}
