import { gql } from '@apollo/client'
import { BodyText, Col, Collapse, Heading, Link, List, Row } from '@farol-ds/react'
import { nullableConnectionNodes } from '@jusbrasil-web/shared-apollo-client/utilities'

import { CourtNavigationTopics_TagFragment } from './__generated__/courts-navigation-topics.graphql'
import styles from './courts-navigation-topics.module.scss'

export interface CourtNavigationTopicsProps {
  tag: CourtNavigationTopics_TagFragment | null
}

type TopicNode = NonNullable<
  NonNullable<NonNullable<CourtNavigationTopics_TagFragment['topics']>['edges']>[number]
>['node']
export type ChildrenTopicsNode = NonNullable<
  NonNullable<NonNullable<NonNullable<TopicNode>['childrenTopics']>['edges']>[number]
>['node']

const buildCourtLink = (childEdge?: ChildrenTopicsNode | null) => {
  const { alias, acronym, tid } = childEdge || {}
  const formattedAcronym = acronym?.replace(/-/g, '').toLowerCase()
  return (
    <Col xs={12} sm={6} md={4} key={tid} className={styles.item} asChild>
      <List.Item>
        <BodyText asChild>
          <h4>
            <Link
              title={`${alias} (${acronym})`}
              type="tertiary"
              href={`/consulta-processual/${formattedAcronym}/`}
            >
              {`${alias} (${acronym})`}
            </Link>
          </h4>
        </BodyText>
      </List.Item>
    </Col>
  )
}

const renderTopic = (topic: TopicNode) => {
  return (
    <Row>
      <Col xs={12}>
        <Heading size="md" asChild>
          <h3>Consulta processual nos {topic?.alias}</h3>
        </Heading>
      </Col>

      <List className={styles.list}>
        {topic?.childrenTopics?.edges?.map((childEdge) => buildCourtLink(childEdge?.node))}
      </List>
    </Row>
  )
}
export function CourtNavigationTopics(props: CourtNavigationTopicsProps) {
  const { tag } = props

  const jurisTopics = nullableConnectionNodes(tag?.topics) || []

  const parentTopics =
    (jurisTopics.filter((t) => t.childrenTopics?.edges?.length) as TopicNode[]) || []
  const firstTopic = parentTopics[0]
  const otherTopics = parentTopics.slice(1)

  return (
    <Collapse>
      {firstTopic && renderTopic(firstTopic)}

      <Collapse.Content>
        {otherTopics &&
          otherTopics.map((court) => (
            <div key={court?.tid} className={styles.topic}>
              {renderTopic(court)}
            </div>
          ))}
      </Collapse.Content>
    </Collapse>
  )
}

CourtNavigationTopics.fragments = {
  tag: gql`
    fragment CourtNavigationTopics_tag on Tag {
      topics(first: 20) {
        edges {
          node {
            tid
            acronym
            alias
            childrenTopics(first: 50) {
              edges {
                node {
                  tid
                  acronym
                  alias
                }
              }
            }
          }
        }
      }
    }
  `,
}
