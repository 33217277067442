import { ABTestParticipation } from '@jusbrasil-web/shared-ab-test'

export const PPC_EXPERIMENTS = {
  LS_PPC_TOF_EXPERIMENT: 'ls-ppc-tof-experiment',
}

export const PPC_GROUPS = {
  TEMPORARY_PPC_LS_HOME: 'temporary-ppc-ls-home',
}

export const PPC_VARIANTS = {
  CONTROL: 'control',
  VARIANT_A: 'variantA',
}

export const PPC_EXPERIMENTS_CONFIG: ABTestParticipation = {
  experiments: Object.values(PPC_EXPERIMENTS),
  groups: Object.values(PPC_GROUPS),
  participatingOnly: true,
}

export const LS_LP_EXP_COOKIE_NAME = 'ppc_ls_tof_lp_v1'
export const sevenDaysInSeconds = 24 * 60 * 60 * 7
