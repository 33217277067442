import { SEOHowTo } from '../../types'

export interface SEOHowToInput {
  name: string
  stepItems: {
    name: string
    text: string
  }[]
}

export const getHowTo = ({ name, stepItems }: SEOHowToInput): SEOHowTo => ({
  '@context': 'https://schema.org/',
  '@type': 'HowTo',
  name,
  step: stepItems.map((step) => ({
    '@type': 'HowToStep',
    ...step,
  })),
})
