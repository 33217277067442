export const organization = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Jusbrasil',
  description:
    // eslint-disable-next-line max-len
    'Acesse o Jusbrasil para Consulta Processual nos Tribunais, Pesquisa de Jurisprudência, Legislação, Doutrina, Modelos, Peças Processuais Jurídicas e mais para facilitar pesquisas e acompanhar processos. Através da plataforma disponibilizamos informação jurídica gratuita para pessoas e profissionais do Direito, além de ser a maior comunidade de advogados cadastrados que interagem na comunidade e podem ser contatados para auxiliar nos diversos casos das diferentes esferas.',
  url: 'https://www.jusbrasil.com.br',
  alternateName: 'Jus',
  logo: 'https://static.jusbr.com/images/logo/logo-jusbrasil-1200-1200.png',
  sameAs: [
    'https://www.facebook.com/Jusbrasil/',
    'https://twitter.com/jusbrasil',
    'https://www.instagram.com/jusbrasil/',
    'https://www.youtube.com/jusbrasil',
    'https://www.linkedin.com/company/jusbrasil/',
    'https://www.jusbrasil.com.br',
  ],
}

export const website = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: 'https://www.jusbrasil.com.br',
  potentialAction: {
    '@type': 'SearchAction',
    target:
      'https://www.jusbrasil.com.br/busca?utm_source=searchbox_sitelink&q={search_term_string}',
    'query-input': 'required name=search_term_string',
  },
}

export const siteNavigation = {
  '@context': 'http://schema.org',
  '@graph': [
    {
      '@type': 'SiteNavigationElement',
      name: 'Home',
      url: 'https://www.jusbrasil.com.br',
    },
    {
      '@type': 'SiteNavigationElement',
      name: 'Consulta Processual',
      url: 'https://www.jusbrasil.com.br/consulta-processual/',
    },
    {
      '@type': 'SiteNavigationElement',
      name: 'Jurisprudência',
      url: 'https://www.jusbrasil.com.br/jurisprudencia/',
    },
    {
      '@type': 'SiteNavigationElement',
      name: 'Doutrina',
      url: 'https://www.jusbrasil.com.br/doutrina/',
    },
    {
      '@type': 'SiteNavigationElement',
      name: 'Artigos',
      url: 'https://www.jusbrasil.com.br/artigos/',
    },
    {
      '@type': 'SiteNavigationElement',
      name: 'Notícias',
      url: 'https://www.jusbrasil.com.br/noticias/',
    },
    {
      '@type': 'SiteNavigationElement',
      name: 'Diários Oficiais',
      url: 'https://www.jusbrasil.com.br/diarios/',
    },
    {
      '@type': 'SiteNavigationElement',
      name: 'Peças Processuais',
      url: 'https://www.jusbrasil.com.br/pecas/',
    },
    {
      '@type': 'SiteNavigationElement',
      name: 'Modelos',
      url: 'https://www.jusbrasil.com.br/modelos-pecas/',
    },
    {
      '@type': 'SiteNavigationElement',
      name: 'Legislação',
      url: 'https://www.jusbrasil.com.br/legislacao/',
    },
    {
      '@type': 'SiteNavigationElement',
      name: 'Advogados',
      url: 'https://www.jusbrasil.com.br/advogados/',
    },
  ],
}
