import { SEOWebPage } from '../../types'

export interface SEOWebpageInput {
  description: string
  canonicalUrl: string
  name: string
  image: {
    name: string
  }
}

export const getWebpage = ({
  description,
  canonicalUrl,
  name,
  image,
}: SEOWebpageInput): SEOWebPage => ({
  '@context': 'https://schema.org',
  '@type': 'WebPage',
  name,
  description,
  url: canonicalUrl,
  image: {
    '@type': 'ImageObject',
    name: image.name,
    url: 'https://static.jusbr.com/images/logo/logo-jusbrasil-1200-675.png',
    height: '675',
    width: '1200',
  },
})
