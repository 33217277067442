export enum SearchPageError {
  lawsuit_not_found = 'lawsuit_not_found',
  name_not_found = 'name_not_found',
  cpf_not_found = 'cpf_not_found',
  lawsuit_unavailable = 'lawsuit_unavailable',
  unfinished_search = 'unfinished_search',
}

export enum SearchIntentionOptionEnum {
  ENTERPRISE = 'ENTERPRISE',
  OTHER = 'OTHER',
  PARTY = 'PARTY',
  RELATIVE = 'RELATIVE',
  UNKNOWN = 'UNKNOWN',
}

export const SEARCH_ERROR_TEXTS = {
  [SearchPageError.lawsuit_not_found]: {
    title: 'Processo não encontrado',
    description:
      'O processo <QUERY> não foi encontrado nos sistemas dos tribunais. Tente buscar pelo CPF ou pelo nome das pessoas envolvidas.',
  },
  [SearchPageError.name_not_found]: {
    title: 'Não encontramos pessoas com esse nome',
    description:
      'Esta pessoa pode ser parte em processos nos quais o nome <QUERY> aparece com pequenas variações. Tente buscar pelo CPF, por outro nome ou pelo número do processo.',
  },
  [SearchPageError.cpf_not_found]: {
    title: 'Não encontramos pessoas com esse CPF',
    description:
      'Esta pessoa pode ser parte em processos nos quais o CPF <QUERY> não foi informado. Tente buscar por nome ou número do processo.',
  },
  [SearchPageError.lawsuit_unavailable]: {
    title: 'Processo indisponível',
    description:
      'O processo <QUERY> encontra-se arquivado ou em segredo de justiça nos sistemas dos tribunais. Pesquise por um nome ou novo número.',
  },
  [SearchPageError.unfinished_search]: {
    title: 'Busca não concluída',
    description:
      'A busca pelo processo <QUERY> não foi concluída. Busque novamente por um CPF, nome ou número de processo.',
  },
}
