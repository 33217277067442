const ARGOS_HOST = 'http://argos-api.default'

type EventData = {
  name: string
  user_id: string | null
  properties: unknown
}

export class ArgosClient {
  private endpoint: string

  constructor(baseURL: string = ARGOS_HOST) {
    this.endpoint = `${baseURL}/events`
  }

  private sendWithBeacon(data: EventData) {
    const res = navigator.sendBeacon(this.endpoint, JSON.stringify(data))
    if (!res) {
      console.warn(`[Argos] Can not send the event ${data.name} with beacon`)
    }
  }

  private async sendWithFetch(data: EventData, headers: Record<string, string> | object) {
    try {
      const res = await fetch(this.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          ...headers,
        },
        body: JSON.stringify(data),
      })

      if (!res.ok) {
        console.warn(`[Argos] Network response was not ok: ${res.status}`)
      }
    } catch (err) {
      console.warn(`[Argos] Can not send the event ${data.name} with fetch:`, err)
    }
  }

  send(
    userId: string | undefined,
    eventName: string,
    eventProps: unknown = {},
    headers: Record<string, string> | object = {}
  ) {
    if (eventName.indexOf('.') === -1) {
      // argos api expects a dot on every event name to define it's namespace
      throw new Error(
        'Argos metric name should have a defined namespace: ex.: "Namespace.EventType"'
      )
    }

    const data: EventData = {
      name: eventName,
      user_id: userId || null,
      properties: eventProps,
    }

    const hasBeacon = !!(global.navigator && global.navigator.sendBeacon)

    if (hasBeacon) {
      this.sendWithBeacon(data)
    } else {
      this.sendWithFetch(data, headers)
    }
  }
}
