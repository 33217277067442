import { fetchExperiments } from '@jusbrasil-web/lawsuit/shared'
import { GetServerSidePropsContext } from 'next'

import { EXPERIMENTS_CONFIG } from './experiments'
import { PPC_EXPERIMENTS, PPC_EXPERIMENTS_CONFIG } from './ppc-experiments'

export const fetchLawsuitSearchExperiments = async (
  ctx: GetServerSidePropsContext,
  shouldFetchPPCExperiment: boolean
) => {
  const experimentsConfig = shouldFetchPPCExperiment ? PPC_EXPERIMENTS_CONFIG : EXPERIMENTS_CONFIG
  let [experiments] = await Promise.all([fetchExperiments(ctx, experimentsConfig)])
  const participatingInPPCExperiment =
    shouldFetchPPCExperiment &&
    experiments?.some(
      (experiment) =>
        experiment.experiment === PPC_EXPERIMENTS.LS_PPC_TOF_EXPERIMENT &&
        experiment.participating === true
    )
  if (!participatingInPPCExperiment && shouldFetchPPCExperiment) {
    ;[experiments] = await Promise.all([fetchExperiments(ctx, EXPERIMENTS_CONFIG)])
  }

  return experiments
}
