import { NextApiRequest } from 'next'
import UAParser from 'ua-parser-js'

import { argosClient } from '../../api'

type Cookies = Partial<{
  [key: string]: string
}>

export const appData = {
  app_id: 'lawsuit-search',
}

export const sendMetricsFromServer = (
  eventName: string,
  pid: string,
  data = {},
  headers: Record<string, string> | object = {}
) => {
  try {
    argosClient.send(pid, eventName, data, headers)
  } catch (err) {
    console.error(err)
  }
}

export const buildSearchEventData = (req: NextApiRequest, cookies: Cookies, extraData = {}) => {
  const { headers } = req
  const { 'user-agent': userAgent, referer } = headers
  const ua = UAParser(userAgent)
  const referrer = referer || ''

  const adsCookies = Object.entries(cookies)
    .filter(([cookieKey]) => cookieKey.startsWith('ads_'))
    .reduce((accumulator, [cookieKey, cookieValue]) => {
      return { ...accumulator, [cookieKey]: cookieValue }
    }, {})

  const properties = {
    browser: ua.browser.name,
    os: ua.os.name,
    user_agent: userAgent,
    dvce_type: ua.device.type || 'desktop',
    dvce_ismobile: ua.device.type === 'mobile',
    referrer,
    source: referrer,
  }
  return {
    ...appData,
    ...properties,
    ...extraData,
    ...adsCookies,
    device_id: cookies?.jdid || null,
  }
}

export const getEventData = (
  req: NextApiRequest,
  query: string,
  sanitizedQuery: string,
  fromComponent: string | null,
  eventDocument: string | null,
  hasResults: boolean,
  userId: string,
  isAdmin: string | null
) => {
  const eventData = {
    num_results: 1,
    sanitized_query: sanitizedQuery,
    from_component: fromComponent,
    query,
    documents: eventDocument ? [eventDocument] : [],
    hasResults,
    section: 'consulta-processual',
    user_id: userId,
    isAdmin,
  }

  return buildSearchEventData(req, req.cookies, eventData)
}
