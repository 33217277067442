import { gql, useQuery } from '@apollo/client'
import { Footer } from '@jusbrasil/footer'
import { withTopbarProps } from '@jusbrasil/topbar'
import { withApollo } from '@jusbrasil-web/core-apollo'
import {
  ExperimentsProvider,
  isLitigantUser,
  LawsuitAppPageProps,
  LawsuitTopbar,
  Nullable,
} from '@jusbrasil-web/lawsuit/shared'
import { ABTestParticipateResponse } from '@jusbrasil-web/shared-ab-test'
import { ApolloClientOptions } from '@jusbrasil-web/shared-apollo-client'
import { nullableConnectionNodes } from '@jusbrasil-web/shared-apollo-client/utilities'
import { useGTMDataLayerWithViewer } from '@jusbrasil-web/shared-gtm'
import { withUserSession } from '@jusbrasil-web/shared-user-session'
import { getCookie, setJusbrasilCookie } from '@jusbrasil-web/shared-utils-cookies'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { getFirstQueryParam } from '@jusbrasil-web/shared-utils-url'
import { GetServerSideProps } from 'next'
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import UAParser from 'ua-parser-js'

import {
  CourtNavigation,
  Faq,
  Home,
  LawsuitHistoryViews,
  PageHead,
  SearchOnboarding,
} from '../../components'
import { CourtTopicType } from '../../components/lawsuit-search-home/types'
import { fetchLawsuitSearchExperiments, SearchPageError } from '../../utils'
import { PPC_CAMPAIGNS, PPC_TOP_OF_FUNNEL_CAMPAIGNS } from '../../utils/ppc-campaigns'
import {
  LS_LP_EXP_COOKIE_NAME,
  PPC_EXPERIMENTS,
  PPC_VARIANTS,
  sevenDaysInSeconds,
} from '../../utils/ppc-experiments'
import { SearchRootQuery } from './__generated__/lawsuit-search.graphql'
import styles from './lawsuit-search.module.scss'

type TagTopic = NonNullable<NonNullable<SearchRootQuery['root']['tag']>['topics']>
type TopicNode = NonNullable<NonNullable<TagTopic['edges']>[number]>['node']
export type ChildrenTopicsNode = NonNullable<
  NonNullable<NonNullable<NonNullable<TopicNode>['childrenTopics']>['edges']>[number]
>['node']

export const SEARCH_PAGE_QUERY = gql`
  query searchRoot {
    root {
      viewer: me {
        user {
          profiles {
            edges {
              node {
                id
              }
            }
          }
        }
        pid
        hasCaseTracking
        hasPaidPlan
        ...Home_viewer
        ...LawsuitHistoryViews_viewer
        ...useGTMDataLayerWithViewer_viewer
        ...LawsuitTopbar_viewer
      }
      tag(identifier: "JURISPRUDENCIA_HOME") {
        topics(first: 20) {
          edges {
            node {
              childrenTopics(first: 50) {
                edges {
                  node {
                    acronym
                    alias
                  }
                }
              }
            }
          }
        }
        ...CourtNavigation_tag
      }
      lawsuitHistory: crmLawsuitsHistory {
        number
      }
      ...LawsuitHistoryViews_root
    }
  }
  ${Home.fragments.viewer}
  ${CourtNavigation.fragments.tag}
  ${LawsuitHistoryViews.fragments.viewer}
  ${LawsuitHistoryViews.fragments.root}
  ${useGTMDataLayerWithViewer.fragments.viewer}
  ${LawsuitTopbar.fragments.viewer}
`

const FROM_COMPONENT = 'LawsuitSearchHome'

export interface SearchPageProps {
  court: string
  deviceType: string
  isLogged: boolean
  litigantUser: boolean
  adsCookies?: Record<string, string>
  experiments: ABTestParticipateResponse[]
  isPaidCampaign: boolean
  currentUrl?: string
}

const parseSearchPageError = (searchParams: string): SearchPageError | undefined => {
  const redirectError = new URLSearchParams(searchParams)?.get('redirect')?.trim() || ''
  if (Object.keys(SearchPageError).includes(redirectError)) {
    return SearchPageError[redirectError as keyof typeof SearchPageError]
  }
}

const extractCourtTopic = (topics?: TagTopic | null, court?: string): CourtTopicType | null => {
  if (!court) return null
  if (!topics) return null
  const jurisTopics = nullableConnectionNodes(topics) || []
  const flattenList = jurisTopics
    ? (jurisTopics.flatMap((topic) => [
        topic,
        ...(topic.childrenTopics?.edges ?? []).map((c) => c?.node ?? []),
      ]) as ChildrenTopicsNode[])
    : []

  const courtTopic = flattenList?.filter(
    (t) => t?.acronym?.replace(/-/g, '') === court.toUpperCase()
  )[0]

  if (!courtTopic) return null

  return {
    acronym: courtTopic.acronym,
    alias: courtTopic.alias,
    tid: courtTopic.tid,
  }
}

export function SearchPage(props: SearchPageProps) {
  const {
    court,
    deviceType,
    adsCookies,
    isLogged,
    litigantUser,
    experiments,
    isPaidCampaign,
    currentUrl,
  } = props

  const { data } = useQuery<SearchRootQuery>(SEARCH_PAGE_QUERY)
  const root = data?.root || null
  const viewer = root?.viewer || null
  const tag = root?.tag || null
  const courtTopic = extractCourtTopic(tag?.topics, court)

  const searchParams = useSearchParams() as ReadonlyURLSearchParams
  const pageError = parseSearchPageError(searchParams.toString())

  const activeExperiment = experiments.find((experiment) => experiment.participating === true)

  const lawsuitHistory = root?.lawsuitHistory
  const hasLastLawsuitsVisited = (lawsuitHistory && lawsuitHistory.length > 0) || false
  const shouldShowHistory = isLogged && hasLastLawsuitsVisited
  const isSubscriber = viewer?.hasPaidPlan || false
  const hasMultipleProfiles = (data?.root?.viewer?.user?.profiles?.edges?.length || 0) > 1

  const getEventContext = (): {
    appId?: string
    deviceType?: string
    referrer?: string
    additionalData: Record<string, unknown>
    metadata?: Record<string, Nullable<string | number | boolean>>
  } => {
    const appId = 'lawsuit-search'
    return {
      appId: appId,
      deviceType: deviceType,
      additionalData: { ...adsCookies },
      referrer: global.document && document.referrer,
      metadata: {
        alternative: activeExperiment?.alternative || null,
        experiment: activeExperiment?.experiment || null,
        participatingAbTest: Boolean(activeExperiment?.participating),
      },
    }
  }
  const eventContext = getEventContext()

  const { enrichGTMUserData } = useGTMDataLayerWithViewer(viewer)
  useEffect(() => {
    enrichGTMUserData()
  }, [enrichGTMUserData])

  useEffect(() => {
    const courtFromComponent = courtTopic && `Court${courtTopic?.acronym?.replace(/-/g, '')}`

    if (!pageError) {
      sendMetrics('Search.HomePageViewed', viewer?.pid?.toString(), {
        app_id: eventContext?.appId,
        section: 'consulta-processual-home',
        dvce_ismobile: eventContext?.deviceType === 'mobile' || false,
        dvce_type: eventContext?.deviceType,
        additionalData: eventContext?.additionalData,
        from_component: courtFromComponent || FROM_COMPONENT,
        metadata: {
          ...(eventContext?.metadata || {}),
          hasLastSeenLawsuit: shouldShowHistory,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderPageBody = (pageError?: SearchPageError) => {
    if (pageError) {
      return <Faq noBackground />
    }

    if (shouldShowHistory) {
      return (
        <>
          <LawsuitHistoryViews viewer={viewer} root={root} />
        </>
      )
    }

    return (
      <>
        <SearchOnboarding />
        <Faq />
        <CourtNavigation tag={tag} />
      </>
    )
  }

  return (
    <>
      <PageHead courtTopic={courtTopic} />
      <LawsuitTopbar
        currentNavKey="CONSULTA_PROCESSUAL"
        isLitigantUser={litigantUser}
        isPaidCampaign={isPaidCampaign}
        isSubscriber={isSubscriber}
        hasMultipleProfiles={hasMultipleProfiles}
        viewer={viewer}
      />
      <ExperimentsProvider experiments={experiments}>
        <main>
          <Home
            viewer={viewer}
            courtTopic={courtTopic}
            eventContext={eventContext}
            pageError={pageError}
            currentUrl={currentUrl}
          />

          {renderPageBody(pageError)}
        </main>
      </ExperimentsProvider>

      <Footer className={styles.footer}>
        <Footer.Bottom asChild>
          <div className={styles.menu}>
            <Footer.Menu />
          </div>
        </Footer.Bottom>

        <Footer.Bottom>
          <Footer.Copyright />
          <Footer.Social />
        </Footer.Bottom>
      </Footer>
    </>
  )
}

const getServerSideProps: GetServerSideProps<SearchPageProps & LawsuitAppPageProps> = async (
  ctx
) => {
  const { req, params, query } = ctx
  const { headers, url } = req
  const { 'user-agent': userAgent } = headers
  const ua = UAParser(userAgent)
  const court = getFirstQueryParam(params?.court)
  const utmCampaign = getFirstQueryParam(ctx.query.utm_campaign)
  const isPaidCampaign = !!utmCampaign && PPC_CAMPAIGNS.includes(utmCampaign)
  const litigantUser = isLitigantUser(req.cookies)

  const shouldFetchPPCExperiment =
    !!utmCampaign && PPC_TOP_OF_FUNNEL_CAMPAIGNS.includes(utmCampaign)

  const experiments = await fetchLawsuitSearchExperiments(ctx, shouldFetchPPCExperiment)

  const participatingInPPCExperiment = experiments?.some(
    (experiment) =>
      experiment.experiment === PPC_EXPERIMENTS.LS_PPC_TOF_EXPERIMENT &&
      experiment.participating === true &&
      experiment.alternative === PPC_VARIANTS.VARIANT_A
  )

  if (participatingInPPCExperiment) {
    const queryParams = {
      utm_source: query.utm_source || '',
      utm_medium: query.utm_medium || '',
      utm_campaign: query.utm_campaign || '',
      utm_term: query.utm_term || '',
      utm_content: query.utm_content || '',
      campaign: 'true',
    } as Record<string, string>
    const queryParamString = new URLSearchParams(queryParams).toString()

    if (getCookie(req, LS_LP_EXP_COOKIE_NAME) !== 'true') {
      setJusbrasilCookie(ctx.res, LS_LP_EXP_COOKIE_NAME, true, {
        maxAge: sevenDaysInSeconds,
      })

      return {
        redirect: {
          permanent: false,
          destination: `https://www.jusbrasil.com.br/a/consultar-meu-processo?${queryParamString}`,
        },
      }
    }
  }

  const gqlClientUri = process.env.NEXT_PUBLIC_LAWSUIT_SEARCH_CSR_GQL_URL
  const gqlServerUri = process.env.LAWSUIT_SEARCH_SSR_GQL_URL

  const apolloOptions: ApolloClientOptions = {
    csrUri: gqlClientUri,
    ssrUri: gqlServerUri,
  }
  const { client, withApolloProps } = withApollo(ctx, {
    clientOverrides: apolloOptions,
  })

  const { data } = await client.query<SearchRootQuery>({ query: SEARCH_PAGE_QUERY })
  const isLogged = !!data.root.viewer?.pid

  return {
    props: {
      isPaidCampaign,
      court,
      isLogged,
      litigantUser: litigantUser,
      experiments,
      currentUrl: `https://${headers.host}${url}`,
      deviceType: ua.device.type || 'desktop',
      ...(await withTopbarProps(ctx)),
      ...withApolloProps(),
      ...withUserSession(ctx),
    },
  }
}

export const SearchPageExports = { getServerSideProps }
