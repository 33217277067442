import { BodyText, Col, Heading, Row } from '@farol-ds/react'
import { useExperiments } from '@jusbrasil-web/lawsuit/shared'
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation'

import { EXPERIMENTS, SEARCH_ERROR_TEXTS, SearchPageError, VARIANTS } from '../../../utils'
import { CourtTopicType } from '../types'
import styles from './home-title.module.scss'

export interface HomeTitleProps {
  courtTopic: CourtTopicType | null
  pageError?: SearchPageError
}

const getHeadingTexts = (
  courtTopic: CourtTopicType | null,
  query: string,
  isLawsuitSearchTopbarExperiment: boolean,
  pageError?: SearchPageError
) => {
  if (pageError) {
    const title = SEARCH_ERROR_TEXTS[pageError]?.title || ''
    let description = SEARCH_ERROR_TEXTS[pageError]?.description || ''
    description = description.replace('<QUERY>', query)

    return {
      title,
      description,
    }
  }

  const acronym = courtTopic?.acronym || ''
  const alias = courtTopic?.alias || ''

  const title = courtTopic
    ? `Consulta processual no ${acronym?.toUpperCase()}`
    : 'Consulta processual'

  const getDescription = (): string => {
    if (courtTopic) {
      if (isLawsuitSearchTopbarExperiment) {
        return `Consultar processos por CPF, nome ou número no ${alias}`
      }
      return `Consultar processos por CPF, nome ou número em um clique no ${alias}`
    }

    if (isLawsuitSearchTopbarExperiment) {
      return 'Consultar processos por CPF, nome ou número'
    }

    return 'Consultar processos por CPF, nome ou número em um clique'
  }

  const description = getDescription()

  return { title, description }
}

export function HomeTitle(props: HomeTitleProps) {
  const { pageError, courtTopic } = props
  const searchParams = useSearchParams() as ReadonlyURLSearchParams
  const query = new URLSearchParams(searchParams.toString())?.get('q') || ''

  const { isParticipating } = useExperiments()

  const isLawsuitSearchTopbarExperiment =
    isParticipating(EXPERIMENTS.LAWSUIT_SEARCH_TOPBAR, VARIANTS.VARIANT_B) ||
    isParticipating(EXPERIMENTS.LAWSUIT_SEARCH_TOPBAR, VARIANTS.VARIANT_C)

  const { title, description } = getHeadingTexts(
    courtTopic,
    query,
    isLawsuitSearchTopbarExperiment,
    pageError
  )

  return (
    <Row className={styles.container}>
      <Col xs={12} md={8} className={styles.header}>
        <Heading size="2xl" asChild>
          <h1 className={styles.title}>{title}</h1>
        </Heading>

        <BodyText size="md" asChild>
          <h2 className={styles.text}>{description}</h2>
        </BodyText>
      </Col>
    </Row>
  )
}
