import { BodyText, Col, Container, Heading, Row } from '@farol-ds/react'

import styles from './faq.module.scss'
import { FaqAccordion } from './faq-accordion'

interface FaqProps {
  noBackground?: boolean
}

export function Faq({ noBackground = false }: FaqProps) {
  const backgroundClass = noBackground ? '' : styles.background
  const sectionClass = `${styles.section} ${backgroundClass}`

  return (
    <section className={sectionClass}>
      <Container>
        <Row>
          <Col xs={12} sm={6} lg={5} className={styles.header}>
            <Heading size="xl" className={styles.title} asChild>
              <h2>Dúvidas?</h2>
            </Heading>
            <BodyText>
              Entenda melhor como funciona a consulta e acompanhamento processual.
            </BodyText>
          </Col>
          <Col xs={0} lg={1} />
          <Col xs={12} sm={6}>
            <FaqAccordion />
          </Col>
        </Row>
      </Container>
    </section>
  )
}
