import { gql, useMutation } from '@apollo/client'
import {
  Col,
  defaultFilterOptions,
  FormControl,
  Row,
  SearchAutocomplete,
  SearchAutocompleteElement,
  Separator,
} from '@farol-ds/react'
import {
  AUTOCOMPLETE_THRESHOLD,
  AutocompleteOption,
  BASE_URL,
  BEGIN_YEAR,
  BOUNCE_SOURCE_TYPE,
  CNJ_PATTERN,
  CNJ_YEAR_REGEX,
  CPF_PATTERN,
  CPF_REGEX,
  CURRENT_YEAR,
  ERROR_MESSAGES,
  ERROR_TYPES,
  errorToEventName,
  EventContext,
  getQueryId,
  handleBounceUserActivity,
  IdentityValidationModal,
  InitialState,
  notFoundEventData,
  PLACEHOLDER_TEXT,
  registerEvent,
  registerSearchViewedEvent,
  SEARCH_URL,
  sendSuggestionEvent,
  useAutocompleteRequest,
  useBrowserBackButtonListener,
  useHistoryItems,
  UTM_SOURCE,
} from '@jusbrasil-web/lawsuit/shared'
import {
  attachBrowserBackButtonListener,
  cleanupHistoryState,
} from '@jusbrasil-web/shared-browser-back-button-listener'
import { useGA4Dispatcher } from '@jusbrasil-web/shared-ga4'
import {
  COMPONENT_CLASS,
  COMPONENT_NAME,
  GA4SearchEvent,
  storeComponentOriginForNextPage,
} from '@jusbrasil-web/shared-ga4-events'
import { useGTM } from '@jusbrasil-web/shared-gtm'
import { LawsuitNumber } from '@jusbrasil-web/shared-utils-cnj'
import {
  destroyJusbrasilCookie,
  getCookie,
  setJusbrasilCookie,
} from '@jusbrasil-web/shared-utils-cookies'
import { validateCpf } from '@jusbrasil-web/shared-utils-validators'
import { useSearchParams } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { useIMask } from 'react-imask'

import { useGetLawsuitSearchInteractionAmount } from '../../../utils'
import {
  HandleSearchCallbackResult,
  InterceptSearchType,
  LawsuitSearchIntentionWrapper,
} from '../../lawsuit-search-intention-wrapper'
import { HomeSearch_ViewerFragment } from './__generated__/home-search.graphql'
import { FROM_COMPONENT } from './constants'
import styles from './home-search.module.scss'
import { SearchTypeEnum } from './types'

export interface HomeSearchProps {
  eventContext: EventContext
  viewer: HomeSearch_ViewerFragment | null
  currentUrl?: string
}

export const LAWSUIT_SEARCH_FORM_MUTATION = gql`
  mutation LawsuitSearchFormSearchLawsuitMutation($input: SearchLawsuitInput!) {
    searchLawsuit(input: $input) {
      tid
      lawsuitId
      lawsuitUrl
      lawsuitNumber
      lawsuitHasCover
    }
  }
`

export const createBounceUserActivityMutation = gql`
  mutation LawsuitSearchFormCreateBounceUserActivityMutation(
    $input: CreateBounceUserActivityInput!
  ) {
    createBounceUserActivity(input: $input) {
      success
    }
  }
`

const SEARCH_AMOUNT_LIMIT = 10

export function HomeSearch(props: HomeSearchProps) {
  const { viewer, eventContext, currentUrl } = props

  const { sendGA4Event } = useGA4Dispatcher()

  const pid = viewer?.pid.toString() || null

  const queryId = getQueryId(eventContext)
  const searchModalOpen = 'searchAutocompleteModalOpen'

  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<AutocompleteOption[]>([])
  const [opts] = useState({
    mask: [{ mask: CPF_PATTERN }, { mask: CNJ_PATTERN }, { mask: String }],
  })
  const [state, setState] = useState<InitialState>({
    errorMessage: '',
    hasError: false,
    query: '',
  })
  const urlSearchParams = useSearchParams()

  const formRef = useRef<HTMLFormElement>(null)
  const searchAutoCompleteRef = useRef<SearchAutocompleteElement>(null)

  const { ref, maskRef } = useIMask(opts)
  const value = ref.current?.value

  const redirectError =
    new URLSearchParams(urlSearchParams?.toString())?.get('redirect')?.trim() || ''
  const errorEvent = errorToEventName(redirectError)
  const fromComponent = errorEvent == '' ? FROM_COMPONENT : errorEvent

  const { lawsuitSearchAmount } = useGetLawsuitSearchInteractionAmount()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isValidatedSuccess, setIsValidatedSuccess] = useState(viewer?.isCpfValid)

  const [queryEventData, setQueryEventData] = useState<string>()

  const showIdentityValidationModal =
    !!lawsuitSearchAmount && lawsuitSearchAmount >= SEARCH_AMOUNT_LIMIT && !isValidatedSuccess

  const accessLimitReached =
    'Você alcançou o limite de consultas processuais. Verifique a sua identidade para continuar.'
  const identitySuccess = 'Agora você pode continuar com sua consulta processual.'
  const flowStepsBodyText = { accessLimitReached, identitySuccess }

  const eventData = {
    from_component: fromComponent,
    app_id: eventContext?.appId,
    query_id: queryId,
    dvce_ismobile: eventContext?.deviceType === 'mobile' || false,
    dvce_type: eventContext?.deviceType,
    ...eventContext?.additionalData,
    metadata: { ...eventContext?.metadata },
  }

  const topicEventData = {
    ...eventData,
    from_component: `${FROM_COMPONENT}Limit`,
    metadata: {
      ...eventData?.metadata,
      ...(queryEventData ? { query: queryEventData } : {}),
    },
  }

  useEffect(() => {
    registerEvent('SearchLawsuit.Viewed', pid, eventData, {})

    if (errorEvent) {
      registerErrorPageEvents()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const gtm = useGTM()
  useEffect(() => {
    gtm.push({ ga4_resource_id: null, ga4_content_group: 'home', ga4_section: 'lawsuit' })
  }, [gtm])

  const [createBounceUserActivity] = useMutation(createBounceUserActivityMutation, {
    onError: (error) => console.warn(error),
  })

  const [searchLawsuit] = useMutation(LAWSUIT_SEARCH_FORM_MUTATION)

  function registerGA4Events(query: string, hasResults: boolean) {
    const eventData = {
      searchTerm: query || '',
      resultPage: null,
      totalReturnedResults: hasResults ? 1 : 0,
      componentClass: COMPONENT_CLASS.SEARCH_BAR,
      componentName: COMPONENT_NAME.SEARCH_BAR_HOME,
    }

    sendGA4Event(new GA4SearchEvent(eventData))
  }

  function registerErrorPageEvents() {
    const fromComponentSearch = getCookie(null, 'from_component_search')
    destroyJusbrasilCookie(null, 'from_component_search')

    const query = new URLSearchParams(urlSearchParams?.toString())?.get('q')?.trim() || ''
    registerSearchViewedEvent(query, null, false, fromComponentSearch, pid, eventContext)
    const data = notFoundEventData(redirectError)
    registerEvent('SearchLawsuit.NotFound', pid, eventData, { query, ...data })
    registerGA4Events(query, false)

    const now = new Date()
    const in30Days = new Date(now.setDate(now.getDate() + 30))
    setJusbrasilCookie(null, 'from_component_search', fromComponent, {
      expires: in30Days,
    })
  }

  function cpfSearch(query: string) {
    registerEvent('SearchLawsuit.Submitted', pid, eventData, { query, search_type: 'CPF' })

    const clearedQuery = query.replace(/[^\d]+/g, '')
    const searchUrl = `/consulta-processual/goto/cpf/${clearedQuery}/?query_id=${queryId}&from_component=${fromComponent}`

    storeComponentOriginForNextPage({
      componentClass: COMPONENT_CLASS.SEARCH_BAR,
      componentName: COMPONENT_NAME.SEARCH_BAR_HOME,
      searchTerm: query,
      searchMethod: null,
    })

    window.location.assign(searchUrl)
  }

  function cnjSearch(query: string) {
    registerEvent('SearchLawsuit.Submitted', pid, eventData, { query, search_type: 'CNJ' })
    searchLawsuit({ variables: { input: { cnjNumber: query } } })
      .then((result) => {
        const { searchLawsuit } = result.data
        const { tid: topicId, lawsuitId, lawsuitUrl: fullUrl } = searchLawsuit || {}
        const document = lawsuitId ? `LAWSUIT-${lawsuitId}` : null

        if (topicId) {
          handleBounceUserActivity(
            topicId,
            BOUNCE_SOURCE_TYPE,
            UTM_SOURCE,
            createBounceUserActivity
          )

          if (value) {
            registerSearchViewedEvent(value, document, true, fromComponent, pid, eventContext)
            registerGA4Events(value, true)
          }
          const topicUrl = `${fullUrl}/?query_id=${queryId}`
          window.location.assign(topicUrl)
        } else {
          value && registerGA4Events(value, false)
          const lawsuitUrl = `${BASE_URL}/processos/consulta/${lawsuitId}/?query_id=${queryId}`
          window.location.assign(lawsuitUrl)
        }
      })
      .catch((err) => console.error(err))
  }

  function personSearch(query: string) {
    const encodedQuery = encodeURIComponent(query.trim()).replace(/%20/g, '+')
    const clearedQuery = encodedQuery.replace(/(\d)([._]+)/g, '$1')
    const searchUrl = `${BASE_URL}/pessoa/busca?q=${clearedQuery}&query_id=${queryId}`

    storeComponentOriginForNextPage({
      componentClass: COMPONENT_CLASS.SEARCH_BAR,
      componentName: COMPONENT_NAME.SEARCH_BAR_HOME,
    })

    window.location.assign(searchUrl)
  }

  useBrowserBackButtonListener()

  const handleFocus = (focused: boolean) => {
    if (focused) {
      attachBrowserBackButtonListener(searchModalOpen, () => {
        searchAutoCompleteRef.current?.close()
      })
    } else {
      cleanupHistoryState(searchModalOpen)
    }
  }

  const checkIdentityValidation = (interceptSearch: InterceptSearchType) => {
    const clearedValue = value?.replace(/[^\d]+/g, '') || value
    setQueryEventData(clearedValue)

    if (showIdentityValidationModal && value?.length) {
      setIsModalOpen(true)
      return
    }
    interceptSearch(handleSubmit)
  }

  const handleSubmit = (): HandleSearchCallbackResult => {
    cleanupHistoryState(searchModalOpen)

    const isCPFNumber = value?.match(CPF_REGEX)
    const isCPFValid = value && validateCpf(value)

    const parsedValue = value?.replace(/[_.-]/g, '')?.trim() || ''
    const lawsuitNumber = new LawsuitNumber(parsedValue)

    destroyJusbrasilCookie(null, 'from_component_search')

    const now = new Date()
    const in30Days = new Date(now.setDate(now.getDate() + 30))
    setJusbrasilCookie(null, 'from_component_search', fromComponent, {
      expires: in30Days,
    })

    if (!value?.length) {
      registerEvent('SearchLawsuit.Submitted', pid, eventData, { query: '', search_type: 'null' })
      setState({ ...state, hasError: true, errorMessage: ERROR_MESSAGES.EMPTY_QUERY })
      return { isFormValid: false }
    }

    if (isCPFNumber && !isCPFValid) {
      setState({ ...state, hasError: true, errorMessage: ERROR_MESSAGES.INVALID_CPF_NUMBER })

      const eventArgs = { query: value, search_type: 'CPF' }
      registerEvent('SearchLawsuit.Submitted', pid, eventData, eventArgs)
      registerEvent('SearchLawsuit.NotFound', pid, eventData, {
        ...eventArgs,
        error_type: ERROR_TYPES.INVALID_CPF,
      })
      return { isFormValid: false }
    }

    setState({ ...state })

    history.putTerm(value).catch((err) => {
      console.error(err)
    })

    if (isCPFNumber && isCPFValid) {
      return {
        isFormValid: true,
        searchType: SearchTypeEnum.CPF,
        searchValue: value,
        successCallback: () => cpfSearch(value),
      }
    }

    const yearMatchCnj = value?.match(CNJ_YEAR_REGEX)
    if (yearMatchCnj != null) {
      const year = parseInt(yearMatchCnj[0].replace(/\./g, '').trim(), 10)
      if (year < BEGIN_YEAR || year > CURRENT_YEAR) {
        const eventArgs = { query: value, search_type: 'CNJ' }
        registerEvent('SearchLawsuit.Submitted', pid, eventData, eventArgs)
        registerEvent('SearchLawsuit.NotFound', pid, eventData, {
          ...eventArgs,
          error_type: ERROR_TYPES.INVALID_DATE,
        })
        setState({
          ...state,
          query: value,
          hasError: true,
          errorMessage: ERROR_MESSAGES.INVALID_CNJ_NUMBER,
        })

        return { isFormValid: false }
      }
    }

    if (lawsuitNumber.isValid) {
      return {
        isFormValid: true,
        searchType: SearchTypeEnum.CNJ,
        searchValue: value,
        successCallback: () => cnjSearch(value),
      }
    }

    return {
      isFormValid: true,
      searchType: SearchTypeEnum.Search,
      searchValue: value,
      successCallback: () => personSearch(value),
    }
  }

  const { mountHistoryItems, history } = useHistoryItems()
  const request = useAutocompleteRequest({ setOptions, setLoading, mountHistoryItems })

  function handleValueChange(value: string) {
    setState({ ...state, hasError: false, errorMessage: '' })
    request.cancel()

    const shouldDisplayAutocomplete = value.length > AUTOCOMPLETE_THRESHOLD

    if (shouldDisplayAutocomplete) {
      setLoading(true)
      request(value)
      return
    }

    request.flush()
    setLoading(false)

    setOptions(options.filter((option) => option.history))
  }

  function handleIdentityValidationModalCallback(
    modalStatus: boolean,
    interceptSearch: InterceptSearchType
  ) {
    setIsModalOpen(modalStatus)
    if (value && isValidatedSuccess) interceptSearch(handleSubmit)
  }

  useEffect(() => {
    history
      .onReady()
      .then(() => mountHistoryItems().then(setOptions))
      .catch((err) => {
        console.log('History not ready', err)
      })
  }, [history, mountHistoryItems])

  return (
    <LawsuitSearchIntentionWrapper viewer={viewer} eventContext={eventContext}>
      {({ interceptSearch }) => (
        <>
          <Row className={styles.search}>
            <Col xs={12} md={8}>
              <form
                action={SEARCH_URL}
                method="GET"
                onSubmit={(evt) => {
                  evt.preventDefault()
                  checkIdentityValidation(interceptSearch)
                }}
                aria-label="consulta processual"
                ref={formRef}
              >
                <FormControl error={state.hasError}>
                  <SearchAutocomplete
                    role="search"
                    size="lg"
                    ref={searchAutoCompleteRef}
                    onFocusedChange={handleFocus}
                  >
                    <SearchAutocomplete.Trigger
                      name="q"
                      loading={loading}
                      onValueChange={(_, value) => handleValueChange(value)}
                      placeholder={PLACEHOLDER_TEXT}
                      ref={ref}
                      data-testid="search-input"
                      searchProps={{
                        onValueClear: (_) => {
                          maskRef.current?.updateValue()
                        },
                      }}
                    />
                    <SearchAutocomplete.Menu
                      dangerouslyLabelAsHTML
                      options={options}
                      filterOptions={(options, input) => {
                        const escapedInput = input.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
                        return options.filter((option) => {
                          // do not try to filter or highlight from api autocomplete
                          if (!option.history) return true

                          // update label with highlighted input
                          option.label = option.value.replace(
                            new RegExp(`(${escapedInput})`, 'gi'),
                            '<strong>$1</strong>'
                          )

                          // filter history items based on input using default criteria
                          return defaultFilterOptions([option], input).length > 0
                        })
                      }}
                      onOptionRemove={(option) => {
                        const item = option.history && history.getByTerm(option.value)
                        if (!item) return
                        history.remove(item).catch((err) => {
                          console.error(err)
                        })
                      }}
                      onOptionSelect={(option) => {
                        history.putTerm(option.value).catch((err) => {
                          console.error(err)
                        })

                        const selectedIndex = options.findIndex((item) => item === option)
                        sendSuggestionEvent(option, selectedIndex, pid, fromComponent)

                        setTimeout(() => formRef.current?.requestSubmit(), 0)
                      }}
                      onCurrentOptionChange={() => maskRef.current?.updateValue()}
                    />
                  </SearchAutocomplete>
                  <FormControl.ErrorMessage className={styles.hasError}>
                    {state.errorMessage}
                  </FormControl.ErrorMessage>
                </FormControl>
              </form>
            </Col>
          </Row>
          <IdentityValidationModal
            viewer={viewer}
            isOpen={isModalOpen}
            onOpenChange={(modalStatus) =>
              handleIdentityValidationModalCallback(modalStatus, interceptSearch)
            }
            eventData={topicEventData}
            validateCallback={(isValidated) => setIsValidatedSuccess(isValidated)}
            flowStepsBodyText={flowStepsBodyText}
            currentUrl={currentUrl}
          />
          <Separator orientation="horizontal" />
        </>
      )}
    </LawsuitSearchIntentionWrapper>
  )
}

HomeSearch.fragments = {
  viewer: gql`
    fragment HomeSearch_viewer on Profile {
      pid
      isCpfValid
      ...LawsuitSearchIntentionWrapper_viewer
      ...IdentityValidationModal_viewer
    }

    ${LawsuitSearchIntentionWrapper.fragments.viewer}
    ${IdentityValidationModal.fragments.viewer}
  `,
}
