import { gql } from '@apollo/client'
import { Col, Container, Heading, Row } from '@farol-ds/react'

import { CourtNavigation_TagFragment } from './__generated__/courts-navigation.graphql'
import styles from './courts-navigation.module.scss'
import { CourtNavigationTopics } from './courts-navigation-topics'

export interface CourtNavigationProps {
  tag: CourtNavigation_TagFragment | null
}

export function CourtNavigation(props: CourtNavigationProps) {
  const { tag } = props
  return (
    <section>
      <Container className={styles.container}>
        <Row>
          <Col xs={12}>
            <Heading size="xl" className={styles.title} asChild>
              <h2>Consulta processual por Tribunal</h2>
            </Heading>
          </Col>
        </Row>

        <CourtNavigationTopics tag={tag} />
      </Container>
    </section>
  )
}

CourtNavigation.fragments = {
  tag: gql`
    fragment CourtNavigation_tag on Tag {
      ...CourtNavigationTopics_tag
    }
    ${CourtNavigationTopics.fragments.tag}
  `,
}
