import { organization, siteNavigation, website } from '../../static-fields'
import { SEOAllSchemaTypes, SEODynamicSchemaTypes, SEOSchema } from '../../types'

export const getStructuredDataSchema = (props: SEODynamicSchemaTypes): SEOSchema => {
  const { faq, breadcrumb, article, itemList, webpage, howTo } = props
  const graphElements = [
    organization,
    website,
    siteNavigation,
    faq,
    breadcrumb,
    howTo,
    webpage,
    itemList,
    article,
  ].filter((el): el is SEOAllSchemaTypes => !!el)

  return {
    '@context': 'https://schema.org',
    '@graph': graphElements,
  }
}
